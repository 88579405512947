import React, { useState, Ref } from "react";
import { Accordion } from "../accordion/Accordion";
import { SearchBar } from "../searchBox/SearchBar";
import { UserAvatar } from "../userAvatar/UserAvatar";
import { useAppDispatch, useAppSelector } from "../../appState/reduxTypedHooks";
import {
  logoutUser,
  setModal,
} from "../../appState/componentSlices/userSlice/userLoginSlice";
import { getProductInCart } from "../../appState/componentSlices/cartSlice/ProductInCartSlice";
import { toastMessages } from "../../constant/toastMessage";
import { useLocation, useNavigate } from "react-router";
import { setToastMessage } from "../../appState/componentSlices/utilSlice/utilSlice";
import { firebaselogOut } from "../../utils/userAuth";
import Avatar from "../avatar/Avatar";

type NavbarProps = {
  profileRef: Ref<HTMLDivElement> | undefined;
  showProfileBar?: boolean;
  setShowProfileBar?: (state: boolean) => void;
};
const NavBar = (props: NavbarProps) => {
  const {
    profileRef,
    showProfileBar = false,
    setShowProfileBar = (state: boolean) => {},
  } = props;
  const {
    userOtp: { isLoggedIn },
    util: { title },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isCheckoutPage = location.pathname === "/checkout";
  const logout = async () => {
    try {
      await dispatch(logoutUser());
      sessionStorage.clear();
      firebaselogOut();
      dispatch(
        setToastMessage({
          type: toastMessages.TYPE.SUCCESS,
          message: toastMessages.MESSAGE.SIGNED_OUT,
          show: true,
        })
      );
      await dispatch(getProductInCart(""));
    } catch (error) {
      console.error(error);
    }
  };
  const login = () => {
    dispatch(setModal(true));
  };
  const avatarProps = {
    isLoggedIn,
    profileRef,
    showProfileBar,
    setShowProfileBar,
    logout,
    login,
  };
  return (
    <div
      className={`nav-container ${isCheckoutPage && "container-on-checkout"}`}
    >
      <div
        className={`nav-header-top ${
          title.toLowerCase() == "home" && `header-container`
        }`}
      >
        {title.toLowerCase() !== "home" ? (
          <button
            className="back-btn"
            onClick={() =>
              location.pathname.includes("/order")
                ? navigate("/")
                : navigate(-1)
            }
          >
            <div className="arrow-left"></div>
          </button>
        ) : (
          false
        )}
        <p className="heading-position">{title !== "Home" && title}</p>
        {isCheckoutPage && <Avatar {...avatarProps} />}
        <div></div>
      </div>
      {!isCheckoutPage && (
        <div className="nav-header-bottom">
          <SearchBar />
          <Avatar {...avatarProps} />
        </div>
      )}
    </div>
  );
};

export { NavBar };
