import { useEffect, useState } from "react";

import { AddressAccordionProps } from "../addressAccordion/AddressAccordion";
import { useAppDispatch } from "../../appState/reduxTypedHooks";

function TimeSlotAccordion({
  config,
  checkoutData,
  setCheckoutData,
}: AddressAccordionProps) {
  const [threeUpcomingSlots, setThreeUpcomingSlots] = useState<any>({});
  const currentDate = new Date().getTime();
  const dateForNextDay = new Date(currentDate + 1000 * 60 * 60 * 24)
    .toDateString()
    .substring(0, 10);
  const dateForToday = new Date(currentDate).toDateString().substring(0, 10);

  const dispatch = useAppDispatch();
  useEffect(() => {
    const currentTime = new Date().getHours();
    if (currentTime < 10) {
      setThreeUpcomingSlots(config.data[0].timeSlots.slot1);
    } else if (currentTime >= 10 && currentTime < 17) {
      setThreeUpcomingSlots(config.data[0].timeSlots.slot2);
    } else if (currentTime >= 17 && currentTime < 24) {
      setThreeUpcomingSlots(config.data[0].timeSlots.slot3);
    }
  }, [config]);
  useEffect(() => {
    dispatch(
      setCheckoutData({
        ...checkoutData,
        preferredDeliverySlot: threeUpcomingSlots[0],
      })
    );
  }, [threeUpcomingSlots]);

  return (
    <div className="time-slot-accordion-container">
      <label htmlFor="timeSlot">
        Preferred Delivery Slot <sup className="required-field">*</sup>{" "}
      </label>

      {threeUpcomingSlots.length > 0 && (
        <select
          required
          className="time-slot-container"
          name="preferredDeliverySlot"
          id="preferredDeliverySlot"
          onChange={(e) => {
            dispatch(
              setCheckoutData({
                ...checkoutData,
                preferredDeliverySlot: e.target.value,
              })
            );
          }}
          value={checkoutData.preferredDeliverySlot}
        >
          <option
            className="timeslot-option"
            value={`${threeUpcomingSlots[0]}`}
          >
            {threeUpcomingSlots[0].includes("Next Day")
              ? threeUpcomingSlots[0] + " , " + dateForNextDay
              : threeUpcomingSlots[0] + " , " + dateForToday}
          </option>
          <option
            className="timeslot-option"
            value={`${threeUpcomingSlots[1]}`}
          >
            {threeUpcomingSlots[1].includes("Next Day")
              ? threeUpcomingSlots[1] + " , " + dateForNextDay
              : threeUpcomingSlots[1] + " , " + dateForToday}
          </option>
          <option
            className="timeslot-option"
            value={`${threeUpcomingSlots[2]}`}
          >
            {threeUpcomingSlots[2].includes("Next Day")
              ? threeUpcomingSlots[2] + " , " + dateForNextDay
              : threeUpcomingSlots[2] + " , " + dateForToday}
          </option>
        </select>
      )}
    </div>
  );
}

export { TimeSlotAccordion };
