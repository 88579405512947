import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
type AccordionProps = {
  heading?: string;
  children?: any;
  headingColor?: string;
  showDetails?: boolean;
  subHeading?: React.ReactNode;
  footer?: React.ReactNode;
  height?: string;
  innerHeading?: string;
  length?: number;
  count?: number;
  subHeadingLink?: string;
  subHeadingText?: string;
};
function Accordion(props: AccordionProps) {
  const {
    heading,
    headingColor,
    children,
    showDetails,
    subHeading,
    height,
    footer,
    innerHeading,
    length = 0,
    count,
    subHeadingText,
    subHeadingLink,
  } = props;

  const [showData, setShowData] = useState(showDetails);
  useEffect(() => {
    setShowData(showDetails);
  }, [showDetails]);

  return (
    <div className="checkout-page-products">
      <div
        onClick={() => setShowData(!showData)}
        className="products-accordion-header"
      >
        {innerHeading ? (
          <p className="accordion-inner-header">{innerHeading}</p>
        ) : (
          <p className={`accordion-header ${headingColor}`}>
            {heading}
            {length > 0 && <span className="accordion-length">({length})</span>}
          </p>
        )}
        {subHeadingText ? (
          <Link className="accordion-text-link" to={`${subHeadingLink}`}>
            {subHeadingText}
          </Link>
        ) : (
          <img
            src={`${
              showData ? "/Images/downArrow.svg" : "/Images/rightArrow.svg"
            }`}
            alt=""
          />
        )}
      </div>
      {showData ? <div className="children-container">{children}</div> : footer}
    </div>
  );
}

export { Accordion };
