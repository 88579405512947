// create redux thunk and slice for login

import { createSlice } from "@reduxjs/toolkit";

interface ToasterInfo {
  type: "error" | "success" | "info";
  message: string;
  show: boolean;
  timeout: number;
}
const initialState = {
  toaster: <ToasterInfo>{
    type: "error",
    message: "",
    show: false,
    timeout: 5000,
  },
  title: "Home",
  disableFooter: false,
};

export const utilSlice = createSlice({
  name: "util",
  initialState,
  reducers: {
    setToastMessage: (state, action) => {
      state.toaster = action.payload;
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setDisableFooter: (state, action) => {
      state.disableFooter = action.payload;
    },
  },
});
export const { setToastMessage, setTitle, setDisableFooter } =
  utilSlice.actions;
