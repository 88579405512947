// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDLgzUpuJkgr1GoAUI6FODaCg6Pu7ed-fA",
  authDomain: "pstoreapp-d10f5.firebaseapp.com",
  projectId: "pstoreapp-d10f5",
  storageBucket: "pstoreapp-d10f5.appspot.com",
  messagingSenderId: "818974327796",
  appId: "1:818974327796:web:2a0aa9287564c479196fa1",
  measurementId: "G-MVB1SNRKW5",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
console.log({ app });
const auth: any = getAuth(app);
console.log({ auth });
export { auth };
