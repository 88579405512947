import React, { FC, ReactNode, ErrorInfo } from "react";
import { Link } from "react-router-dom";
type ErrorHandlerProps = {
  children: ReactNode;
};

type ErrorHandlerState = {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
};

class ErrorBoundary extends React.Component<
  ErrorHandlerProps,
  ErrorHandlerState
> {
  constructor(props: ErrorHandlerProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true, error, errorInfo: errorInfo });
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  render() {
    const { children } = this.props;
    const { hasError, error } = this.state;
    if (hasError || error) {
      return (
        <div className="error-handler">
          <div className="error-handler-image">
            <img className="error-image" src="/Images/loader.gif" alt="error" />
          </div>
          <div className="error-status-code">
            <div>500</div>
          </div>

          <div className="error-handler-content">
            <div className="error-handler-title">
              Oops! Something went wrong.{" "}
            </div>
            <div className="error-handler-description">
              We apologize for the inconvenience. Please try again later.
            </div>
            <div className="error-handler-button">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => window.location.reload()}
              >
                Reload
              </button>
            </div>
            <div className="back-to-home">
              <Link
                to="/"
                onClick={() => {
                  this.setState({ hasError: false, error: null });
                }}
              >
                Back to Home
              </Link>
            </div>
          </div>
        </div>
      );
    }

    return <>{children}</>;
  }
}

export { ErrorBoundary };
