import { Outlet, useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import useCart from "./hooks/useCart";
import {
  ErrorBoundary,
  Footer,
  Login,
  Modal,
  NavBar,
  Toast,
} from "./components";
import { useAppDispatch, useAppSelector } from "./appState/reduxTypedHooks";
import {
  getAllBrands,
  getAllProducts,
} from "./appState/componentSlices/allProductsFeed";
import ReactPortal from "./utils/ReactPortals";
import "./styles/index.scss";
import {
  getUserDetails,
  setModal,
  setOtpScreen,
} from "./appState/componentSlices/userSlice/userLoginSlice";
import { batch } from "react-redux";
const App = () => {
  const { cartData } = useCart();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const profileRef = useRef<HTMLDivElement>(null);
  const loginRef = useRef<HTMLDivElement>(null);
  const isLoggedIn = useAppSelector(
    ({ userOtp: { isLoggedIn } }) => isLoggedIn
  );
  const fetchCartData = async () => {
    await cartData();
  };

  const { length: cartLength, showModal } = useAppSelector(
    ({ productInCart: { productInCart }, userOtp: { showModal } }) => ({
      length: productInCart.length,
      showModal,
    })
  );
  const [showProfileBar, setShowProfileBar] = useState(false);
  const onOutsideClick = (e: any) => {
    profileRef.current &&
      !profileRef.current.contains(e.target as Node) &&
      setShowProfileBar(false);
    loginRef.current &&
      !loginRef.current.contains(e.target as Node) &&
      (() => {
        dispatch(setModal(false));
        dispatch(setOtpScreen(false));
      })();
  };
  useEffect(() => {
    batch(() => {
      dispatch(
        getAllProducts({
          productBody: { groupBy: "itemCategory", matchByField: {} },
        })
      );
      dispatch(
        getAllBrands({
          productBody: { groupBy: "itemBrandName", matchByField: {} },
        })
      );
      dispatch(getUserDetails());
    });
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      fetchCartData();
    }
  }, [cartLength]);

  return (
    <div className="container" onMouseDown={onOutsideClick}>
      <Toast />
      <NavBar
        profileRef={profileRef}
        showProfileBar={showProfileBar}
        setShowProfileBar={setShowProfileBar}
      />
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
      <ReactPortal>
        <Modal showModal={showModal}>
          <Login loginRef={loginRef} />
        </Modal>
      </ReactPortal>
      <Footer />
    </div>
  );
};

export default App;
