import { useNavigate } from "react-router";
import { OrderInterface } from "../../typeDefination";

interface OrderCardProps {
  order: OrderInterface;
}

const OrderCard = ({ order }: OrderCardProps) => {
  const { _id, orderStatus, totalPayableAmount, shippingAddress, orderDate } =
    order;
  const navigate = useNavigate();
  const date = new Date(orderDate);
  const dateValue = date.toDateString();
  return (
    <div className="order-card" onClick={() => navigate(`/order/${_id}`)}>
      <div className="order-card-header">
        <div className="order-card-header-image">
          <img src="/Images/pizza.jpg" alt="order" />
        </div>
      </div>
      <div className="order-card-body">
        <div className="order-card-body-left">
          <p className="order-card-body-left-text">Order Name</p>
          <p className="order-card-body-left-text">
            <span>&nbsp;&#x20B9;{totalPayableAmount}</span>
          </p>
          <p className="order-card-body-left-text order-status">
            {" "}
            {orderStatus[orderStatus?.length - 1]?.status}
          </p>
        </div>
        <div className="order-card-body-right">
          <p className="order-card-body-right-text"> {dateValue}</p>
        </div>
      </div>
    </div>
  );
};

export { OrderCard };
