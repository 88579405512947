import { useNavigate } from "react-router";
import {
  getCheckoutData,
  setLoader,
  setOrderStatus,
} from "../appState/componentSlices/checkoutSlice/checkoutSlice";
import { useAppDispatch, useAppSelector } from "../appState/reduxTypedHooks";
import useCart from "./useCart";
import { getProductInCart } from "../appState/componentSlices/cartSlice/ProductInCartSlice";
import { batch } from "react-redux";

const useCheckout = (checkoutData: any) => {
  const { cart } = useCart();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const checkout = () => {
    batch(async () => {
      const {
        inputAddress,
        currentLocation,
        contact,
        paymentMethod,
        preferredDeliverySlot,
        addressLandmark,
        city = "",
      } = checkoutData;
      await dispatch(setLoader(true));
      const data = {
        shippingAddress: {
          address: inputAddress,
          currentLocation: currentLocation,
          landmark: addressLandmark,
          city: city,
        },
        contactNumber: contact,
        paymentMethod: paymentMethod,
        timeSlot: preferredDeliverySlot + " " + new Date().toLocaleDateString(),
        cart: cart,
      };
      const response = await dispatch(getCheckoutData({ data }));
      batch(async () => {
        await dispatch(getProductInCart(""));
        await dispatch(setLoader(false));
      });
      // navigate to order page after two seconds
      setTimeout(async () => {
        await dispatch(setOrderStatus(false));
        navigate(`/order/${response.payload?.data?._id}`);
      }, 2000);
    });
  };

  return { checkout };
};
export default useCheckout;
