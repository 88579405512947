import { Product } from "../../appState/componentSlices/allProductsFeed";
import { CartCheckout, Footer, ItemCart, ErrorMessage } from "../../components";
import useCart from "../../hooks/useCart";
import { errorMessages } from "../../constant/errorMessages";
import { useEffect } from "react";
import { useAppDispatch } from "../../appState/reduxTypedHooks";
import {
  setDisableFooter,
  setTitle,
} from "../../appState/componentSlices/utilSlice/utilSlice";
import { headers } from "../../constant/titles";
import { batch } from "react-redux";
const icon = "/Images/emptyCart.svg";
const Cart = () => {
  const { cart } = useCart();
  const dispatch = useAppDispatch();
  // cart.productInCart?.cartTotalSP
  const { productInCart: { cartTotalSP = "", products = [] } = {} } = cart;
  useEffect(() => {
    batch(() => {
      dispatch(setTitle(headers.cart));
      dispatch(setDisableFooter(true));
    });
  });
  return (
    <div className="cart">
      <div className="main-content">
        {products.length > 0 ? (
          <div className="cart-items">
            {products.map((item: Product, index: number) => (
              <ItemCart key={index} {...item} />
            ))}
          </div>
        ) : (
          // will create a componant
          // here you can suggest the name of the componant
          <ErrorMessage icon={icon} message={errorMessages.CART.NO_PRODUCT} />
        )}
      </div>

      {cart?.productInCart && <CartCheckout cartTotalSP={cartTotalSP} />}
    </div>
  );
};

export { Cart };
