import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../appState/reduxTypedHooks";
import { CategoryCard, Footer, LoadingSpinner, NavBar } from "../../components";
import { useEffect, useState } from "react";
import {
  setDisableFooter,
  setTitle,
} from "../../appState/componentSlices/utilSlice/utilSlice";
import { headers } from "../../constant/titles";

const CategoryPage = () => {
  const { id } = useParams();
  const {
    allProducts: categories,
    brands,
    loader,
  } = useAppSelector((state) => state.ProductsData);
  const [categoryOrBrand, setCategoryOrBrand] = useState(categories);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id === "brands") {
      setCategoryOrBrand(brands);
      dispatch(setTitle(headers.brand));
    } else {
      setCategoryOrBrand(categories);
      dispatch(setTitle(headers.category));
    }
    dispatch(setDisableFooter(false));
  }, [id, categories, brands]);

  return (
    <div>
      {loader ? (
        <div className="spinner-container-main">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="category-page-container">
          {categoryOrBrand &&
            categoryOrBrand?.map((category: any) => {
              return <CategoryCard key={category._id} category={category} />;
            })}
        </div>
      )}
    </div>
  );
};

export { CategoryPage };
