import { useEffect, useState } from "react";
import { setModal } from "../../appState/componentSlices/userSlice/userLoginSlice";
import { useAppDispatch, useAppSelector } from "../../appState/reduxTypedHooks";
import useCart from "../../hooks/useCart";
type CartQuantityButton = {
  quantity?: number;
  showAddButton?: boolean;
  product?: any;
  setProductQuantity?: any;
};
const CartQuantityButton = ({
  quantity = 1,
  showAddButton = true,
  product,
  setProductQuantity,
}: CartQuantityButton) => {
  const { addToCart, removeFromCart } = useCart();
  const [counter, setCounter] = useState(quantity);
  const [showInput, setShowInput] = useState(false);
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector((state) => state.userOtp.isLoggedIn);

  const handleAddToCart = () => {
    if (!isLoggedIn) {
      return dispatch(setModal(true));
    }
    addToCart({ product, quantity: counter + 1 });
    setCounter(counter + 1);
  };

  const decreaseCounter = () => {
    if (counter > 1) {
      removeFromCart({ product, quantity: counter - 1 });
      setCounter(counter - 1);
    } else {
      removeFromCart({ product, quantity: counter - 1 });
      setShowInput(false);
    }
  };

  useEffect(() => {
    setProductQuantity(counter);
    if (counter) {
      setShowInput(true);
    }
  }, [counter]);
  return (
    <>
      {!showInput && showAddButton ? (
        <button onClick={handleAddToCart} className="add-to-cart-button">
          ADD
        </button>
      ) : (
        <div className="add-to-cart-btn-container">
          <button
            onClick={decreaseCounter}
            className="add-to-cart-btn substract-btn"
          >
            -
          </button>
          <input
            className="add-to-cart-input"
            type="number"
            min={1}
            value={counter}
            onChange={(e) => setCounter(parseInt(e.target.value))}
          />
          <button onClick={handleAddToCart} className="add-to-cart-btn">
            +
          </button>
        </div>
      )}
    </>
  );
};

export { CartQuantityButton };
