interface TrackOrderCardProps {
  statusHeading: string;
  statusContent: string;
  trackingTime: string;
  orderStatusDone: boolean;
  lastStatus?: boolean;
  trackingDate: string;
}
const TrackOrderCard = (props: TrackOrderCardProps) => {
  const {
    statusHeading,
    statusContent,
    trackingTime,
    orderStatusDone,
    lastStatus,
    trackingDate,
  } = props;
  return (
    <div className="track-order-card">
      <div className="track-order-card-time-container">
        <p className="track-order-card-time">{trackingTime}</p>
        {trackingDate && (
          <p className="track-order-card-date">{trackingDate}</p>
        )}
      </div>
      <div
        className={`track-order-card-check ${lastStatus && "delivery-status"}`}
      >
        <i
          className={`order-status ${
            orderStatusDone ? `fas fa-check order-done` : `order-ongoing`
          }`}
        ></i>
        {!lastStatus && <div className="track-order-spacing-line"></div>}
      </div>
      <div className="track-order-card-status-container">
        <p className="track-order-status-heading">{statusHeading}</p>
        <p className="track-order-status-content">{statusContent}</p>
      </div>
    </div>
  );
};

export { TrackOrderCard };
