export const headers = {
  home: "Home",
  cart: "Cart",
  profile: "Profile",
  checkout: "Checkout",
  orders: "Orders",
  category: "Categories",
  brand: "Brands",
  orderDetail: "Order Details",
};
