import { useAppSelector } from "../../appState/reduxTypedHooks";
import { deliveryStatus } from "../../constant/deliveryStatus";
import { getIndianDate } from "../../utils/getIndianDate";
import { TrackOrderCard } from "../trackOrderCard/TrackOrderCard";

interface TrackOrderProps {
  orderNumber: string;
  orderStatus: orderStatus[];
}
type orderStatus = {
  dateTime: Date;
  isCompleted: boolean;
  status: string;
  step: number;
};
function TrackOrder({ orderNumber, orderStatus = [] }: TrackOrderProps) {
  const getIndianTime = (date: Date) =>
    !date
      ? "--"
      : new Date(date).toLocaleTimeString("en-IN", {
          hour: "2-digit",
          minute: "2-digit",
        });
  const allOrderStatus = [
    {
      statusHeading: "Order Placed",
      statusContent: `Your Order #${orderNumber} has been placed successfully.`,
      statusQueue: 1,
      trackingTime: getIndianTime(orderStatus[0]?.dateTime),
      trackingDate: getIndianDate(orderStatus[0]?.dateTime),
      lastStatus: false,
      orderStatusDone: orderStatus[0]?.isCompleted,
    },
    {
      statusHeading: "Order Confirmed",
      statusContent: `Your order has been confirmed by our store manager.`,
      statusQueue: 2,
      trackingTime: getIndianTime(orderStatus[1]?.dateTime),
      trackingDate: getIndianDate(orderStatus[1]?.dateTime),
      lastStatus: false,
      orderStatusDone: orderStatus[1]?.isCompleted,
    },
    {
      statusHeading: "Order Ready",
      statusContent: `Your order is packed and ready for delivery.`,
      statusQueue: 3,
      trackingTime: getIndianTime(orderStatus[2]?.dateTime),
      trackingDate: getIndianDate(orderStatus[2]?.dateTime),
      lastStatus: false,
      orderStatusDone: orderStatus[2]?.isCompleted,
    },
    {
      statusHeading: "Order Dispatch",
      statusContent: `Our delivery partner is out with your order for delivery. Expect a call from him shortly.`,
      statusQueue: 4,
      trackingTime: getIndianTime(orderStatus[3]?.dateTime),
      trackingDate: getIndianDate(orderStatus[3]?.dateTime),
      lastStatus: false,
      orderStatusDone: orderStatus[3]?.isCompleted,
    },
    {
      statusHeading: "Delivered",
      statusContent: `Your order has been delivered to you.`,
      statusQueue: 5,
      trackingTime: getIndianTime(orderStatus[4]?.dateTime),
      trackingDate: getIndianDate(orderStatus[4]?.dateTime),
      lastStatus: true,
      orderStatusDone: orderStatus[4]?.isCompleted,
    },
  ];
  return (
    <div className="track-order-card-container">
      {allOrderStatus.map((status) => {
        const {
          statusHeading,
          statusContent,
          statusQueue,
          trackingTime,
          lastStatus,
          orderStatusDone,
          trackingDate,
        } = status;
        return (
          <div key={statusHeading}>
            <TrackOrderCard
              orderStatusDone={orderStatusDone}
              statusHeading={statusHeading}
              statusContent={statusContent}
              trackingTime={trackingTime}
              lastStatus={lastStatus}
              trackingDate={trackingDate}
            />
          </div>
        );
      })}
    </div>
  );
}

export { TrackOrder };
