import React, { Ref } from "react";
import { Accordion } from "../accordion/Accordion";
import { UserAvatar } from "../userAvatar/UserAvatar";
import { Link } from "react-router-dom";
type ProfileProps = {
  isLoggedIn?: boolean;
  profileRef: Ref<HTMLDivElement> | undefined;
  showProfileBar?: boolean;
  setShowProfileBar?: (state: boolean) => void;
  logout: () => void;
  login: () => void;
};
const Avatar = ({
  isLoggedIn,
  profileRef,
  showProfileBar,
  setShowProfileBar = () => {},
  logout = () => {},
  login = () => {},
}: ProfileProps) => {
  return (
    <>
      {isLoggedIn ? (
        <div
          className="avatar-container"
          onClick={() => setShowProfileBar(!showProfileBar)}
          ref={profileRef}
        >
          <UserAvatar />
          <Accordion showDetails={showProfileBar}>
            <div className="dropdown-container">
              <Link className="my-profile dropdown-items" to="/profile">
                My Profile
              </Link>
              <p className="dropdown-items" onClick={logout}>
                Sign Out
              </p>
            </div>
          </Accordion>
        </div>
      ) : (
        <div>
          <button className="login-btn" onClick={login}>
            Login
          </button>
        </div>
      )}
    </>
  );
};

export default Avatar;
