import { useEffect, useState } from "react";
import { Product } from "../../appState/componentSlices/allProductsFeed";
import { CartQuantityButton } from "../cartQuantityButton/CartQuantityButton";
import { Accordion } from "../accordion/Accordion";
type ProductCardProps = {
  product: Product;
};
function ProductCard(props: ProductCardProps) {
  const { product } = props;
  const { images = [] } = product;

  const productImage = images[0]?.secure_url || "";
  const {
    itemName = "",
    itemMRPperUnit = 0,
    itemSellingPricePerUnit = 0,
  } = product;
  const [productQuantity, setProductQuantity] = useState(0);
  const [mrp, setMrp] = useState(itemSellingPricePerUnit);
  const Product = {
    productDetails: product,
  };
  const { slabPricing } = product;
  const [productCopy, setProductCopy] = useState<any>(structuredClone(Product));

  // mrp set when quantity changes with respect to slab
  useEffect(() => {
    let index = 0;
    for (const slab of slabPricing) {
      if (
        productQuantity <= slabPricing[index + 1]?.[1] - 1 ||
        !slabPricing[index + 1]
      ) {
        setProductCopy((prev: { productDetails: any }): any => {
          prev.productDetails.itemSellingPricePerUnit = slab[2];
          return prev;
        });
        setMrp(slab[2]);
        break;
      }
      index++;
    }
  }, [productQuantity]);
  return (
    <div className="product-container">
      <div className="product-image-btn-container">
        <div className="product-image-container">
          <img
            className="product-image"
            src={productImage || "/Images/pizza.jpg"}
            alt={itemName}
          />
        </div>
        {product && (
          <CartQuantityButton
            quantity={productQuantity}
            showAddButton={true}
            product={productCopy}
            setProductQuantity={setProductQuantity}
          />
        )}
      </div>
      <div className="product-info">
        <div>
          <div className="product-name">
            {itemName?.length > 15 ? itemName?.slice(0, 15) + "..." : itemName}
          </div>
        </div>
        <div className="mrp-sp-container">
          <p className="product-mrp">MRP: &#x20B9;{itemMRPperUnit}</p>
          <p className="product-sp">&#x20B9;{mrp}</p>
        </div>
      </div>
      {product?.slabPricing.length ? (
        <div className="slap-accordion">
          <Accordion
            heading="Slab Pricing"
            headingColor="green"
            children={
              <table className="salb-table" width="100px">
                <thead>
                  <tr>
                    <th>Range</th>
                    <th className="price-align">Price</th>
                  </tr>
                </thead>

                <tbody>
                  {product?.slabPricing.map(
                    (slabs: number[], idx: number, arr: number[][]) => {
                      return (
                        <tr key={idx}>
                          <td>{`
                                    ${slabs[1]}  ${
                            arr[idx + 1] ? `- ${arr[idx + 1][1] - 1}` : "+"
                          }`}</td>
                          <td className="price-align">{slabs[2]}</td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            }
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export { ProductCard };
