import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../appState/reduxTypedHooks";
import { setToastMessage } from "../../appState/componentSlices/utilSlice/utilSlice";
import { toastMessages } from "../../constant/toastMessage";

const Toast = () => {
  const {
    type,
    message,
    show,
    timeout = 5000,
  } = useAppSelector((state) => state.util.toaster);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        dispatch(
          setToastMessage({
            type: "error",
            message: "",
            show: false,
          })
        );
      }, timeout);
    }
  }, [show, timeout]);
  if (show) {
    return (
      <div className={`toaster ${type}`}>
        <div className="label">{message}</div>
        <div
          className="close-icon"
          onClick={() => {
            dispatch(
              setToastMessage({
                type: toastMessages.TYPE.ERROR,
                message: "",
                show: false,
              })
            );
          }}
        >
          x
        </div>
      </div>
    );
  } else return <div>{false}</div>;
};

export { Toast };
