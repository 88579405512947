const UserAvatar = () => {
  const number = window.sessionStorage.getItem("PStoreApp-phone-number") || "";
  return (
    <div className="user-avatar-container">
      <div className="profile-image">
        <p>{number[0]}</p>
      </div>
    </div>
  );
};

export { UserAvatar };
