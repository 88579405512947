// create a user freindly message for add some products to cart

import { Link } from "react-router-dom";
// props type image and message

interface ErrorMessageProps {
  icon: string;
  message: string;
}

const ErrorMessage = ({ icon, message }: ErrorMessageProps) => {
  return (
    <div className="no-product-message">
      <div className="no-product-message-icon">
        <img className="no-product-message-icon" src={icon} alt="Empty Cart" />
      </div>
      <p className="no-product-message-text">{message}</p>
      <Link to="/" className="no-product-message-button">
        <p className="no-product-message-button-text">Add Products</p>
      </Link>
    </div>
  );
};

export { ErrorMessage };
