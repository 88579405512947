interface CategoriesImagesType {
  [key: string]: string;
}

export const CategoriesImages: CategoriesImagesType = {
  Bakery: "Images/Bakery.png",
  Dairy: "Images/Dairy_ 1.png",
  Stationery: "Images/stationery.png",
  Beverage: "Images/beverage.png",
  "Sweet and Chocolate": "Images/sweet_and_choco.png",
  "Spices and fast food": "Images/fast_food.png",
  "Home and Kitchen": "Images/kitchen_items.png",
  "Oil and ghee": "Images/oil_and_ghee.png",
  Grocery: "Images/Grocery.png",
  "Dairy and Frozen": "Images/Dairy_and_froze.png",
  Staple: "Images/staples.png",
  Miscellanous: "Images/miscellaneous.png",
  "Baby and kids": "Images/baby_and_kids.png",
  "Personal care": "Images/personal_care.png",
  "Packaged Food": "Images/package_foods.png",
  Plastic: "Images/plastic_brand.png",
  Pooja: "Images/pooja.png",
  Electronic: "Images/electronic_item.png",
};
