import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../appState/reduxTypedHooks";
import {
  CartProductsAccordion,
  ErrorMessage,
  OrderSummary,
} from "../../components";
import { CheckoutForm } from "../../components/form/CheckoutForm";

import useCart from "../../hooks/useCart";
import {
  setDisableFooter,
  setTitle,
} from "../../appState/componentSlices/utilSlice/utilSlice";
import { headers } from "../../constant/titles";
import { errorMessages } from "../../constant/errorMessages";
import { batch } from "react-redux";
const icon = "/Images/emptyCart.svg";

const CheckoutPage = () => {
  const { cart } = useCart();
  const {
    productInCart: { length: cartLength = 0 },
  } = cart;
  const dispatch = useAppDispatch();
  useEffect(() => {
    batch(() => {
      dispatch(setDisableFooter(cartLength > 0));
      dispatch(setTitle(headers.checkout));
    });
  }, [cartLength]);

  return (
    <div className="checkout-page-container">
      <div className="main-content">
        {cartLength ? (
          <>
            <div className="checkout-page-products">
              <CartProductsAccordion cart={cart} />
            </div>
            <div className="order-summary">
              <OrderSummary cart={cart} />
            </div>
            <CheckoutForm />
          </>
        ) : (
          <ErrorMessage icon={icon} message={errorMessages.CART.NO_PRODUCT} />
        )}
      </div>
    </div>
  );
};

export { CheckoutPage };
