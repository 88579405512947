import { useEffect } from "react";
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../appState/reduxTypedHooks";
import {
  Product,
  getAllProductsByCategory,
} from "../../appState/componentSlices/allProductsFeed";
import {
  Card,
  ErrorMessage,
  Footer,
  NavBar,
  ProductCard,
} from "../../components";
import LoadingSpinner from "../../components/loader/LoadingSpinner";
import { errorMessages } from "../../constant/errorMessages";
import { batch } from "react-redux";
import {
  setDisableFooter,
  setTitle,
} from "../../appState/componentSlices/utilSlice/utilSlice";
const icon = "/Images/emptyCart.svg";

const ProductList = () => {
  // find category from url path
  const { category, id } = useParams();
  const disptach = useAppDispatch();
  const allProducts = useAppSelector(
    (state) => state.ProductsData.allProductsByCategory
  );
  const loader = useAppSelector((state) => state.ProductsData.loader);
  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error :(</p>;
  useEffect(() => {
    batch(() => {
      disptach(setTitle(category));
      disptach(setDisableFooter(false));
      disptach(
        getAllProductsByCategory({
          productBody: {
            groupBy: id === "brands" ? "itemBrandName" : "itemCategory",
            matchByField: {
              _id: category,
            },
          },
        })
      );
    });
  }, []);
  return (
    <div>
      <div className="products-container-main">
        <div className="products-container">
          {!loader ? (
            allProducts[0]?.products?.length > 0 ? (
              allProducts[0]?.products?.map(
                (product: Product, index: number) => {
                  return (
                    <Card key={index}>
                      <ProductCard product={product} />
                    </Card>
                  );
                }
              )
            ) : (
              <div className="no-products-container">
                <ErrorMessage
                  icon={icon}
                  message={errorMessages.PRODUCT.NO_PRODUCT}
                />
              </div>
            )
          ) : (
            <div className="spinner-container-main">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { ProductList };
