import { useEffect, useState } from "react";
import { CartQuantityButton } from "../cartQuantityButton/CartQuantityButton";
import { useAppSelector } from "../../appState/reduxTypedHooks";
const ItemCart = (item: any) => {
  const [productQuantity, setProductQuantity] = useState(item.productQuantity);
  const cartLength = useAppSelector(
    (state) => state.productInCart.productInCart.length
  );

  const {
    productDetails: {
      itemSellingPricePerUnit = 0,
      itemName = "",
      itemMRPperUnit = 0,
    } = {},
  } = item;
  const [mrp, setMrp] = useState(itemSellingPricePerUnit);
  const [itemCopy, setItemCopy] = useState<any>(structuredClone(item));
  const { slabPricing = [] } = itemCopy?.productDetails;
  // mrp set when quantity changes with respect to slab
  useEffect(() => {
    let index = 0;
    for (const slab of slabPricing) {
      if (
        productQuantity <= slabPricing[index + 1]?.[1] - 1 ||
        !slabPricing[index + 1]
      ) {
        setItemCopy((prev: { productDetails: any }): any => {
          prev.productDetails.itemSellingPricePerUnit = slab[2];
          return prev;
        });
        setMrp(slab[2]);
        break;
      }
      index++;
    }
  }, [productQuantity]);
  useEffect(() => {
    setMrp(itemSellingPricePerUnit);
  }, [cartLength, itemSellingPricePerUnit]);

  return (
    <div className="cart-item-container">
      <button className="cart-item-remove">
        <img src="Images/delete.svg" alt="" />
      </button>
      <div className="cart-item-info">
        <div className="cart-item-name">
          {itemName?.length > 20 ? itemName.slice(0, 15) + "..." : itemName}
        </div>
        <div className="cart-item-mrp price">MRP: &#x20B9;{itemMRPperUnit}</div>
        <div className="cart-item-sp price"> &#x20B9;{mrp}</div>
        <div className="cart-item-item-total">
          Total: {productQuantity * mrp}{" "}
        </div>
      </div>
      <div className="cart-item-quantity">
        <div className="image-container">
          <img className="cart-item-image" src="Images/pizza.jpg" alt="" />
        </div>
        <CartQuantityButton
          quantity={productQuantity}
          showAddButton={false}
          product={itemCopy}
          setProductQuantity={setProductQuantity}
        />
      </div>
    </div>
  );
};

export { ItemCart };
