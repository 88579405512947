import { Ref, useEffect, useState } from "react";
import {
  setPhoneNumber,
  setVerify,
} from "../../appState/componentSlices/userSlice/userLoginSlice";
import { useAppDispatch, useAppSelector } from "../../appState/reduxTypedHooks";
import LoadingSpinner from "../loader/LoadingSpinner";
interface LoginCardProps {
  onSignup: () => {};
  loginRef: Ref<HTMLDivElement> | undefined;
}
function LoginCard({ onSignup, loginRef }: LoginCardProps) {
  const { phoneNumber, isVerify, otpLength, loading } = useAppSelector(
    (state) => state.userOtp
  );
  const [isChecked, setIsChecked] = useState(false);

  const dispatch = useAppDispatch();
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPhone = e.target.value.replace(/\D/g, "");
    const limitedPhone = newPhone.slice(0, 10);

    let formattedPhone = "";

    for (let i = 0; i < limitedPhone.length; i++) {
      if (i === 4) {
        formattedPhone += " ";
      }
      formattedPhone += limitedPhone.charAt(i);
    }

    dispatch(setPhoneNumber(formattedPhone));
  };

  useEffect(() => {
    dispatch(setVerify(phoneNumber.replace(/\s/g, "").length === 10));
  }, [phoneNumber]);

  return (
    <div className="login-card-container" ref={loginRef}>
      <h2>Your Phone</h2>
      <div className="icon-input-container">
        <img src="/Images/flag.svg" alt="flag-icon" />
        <p>+91</p>
        <input
          type="tel"
          pattern="[0-9]{4}-[0-9]{6}"
          placeholder="XXXX XXXXXX"
          value={phoneNumber}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="terms-checkbox">
        <input
          className="terms-checkbox-input"
          type="checkbox"
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
        />
        <label className="terms-checkbox-label" htmlFor="terms-checkbox-input">
          By logging in, you agree to our
          <a
            className="terms-checkbox-label-a"
            target="_blank"
            href="/terms-and-conditions"
          >
            Terms and Conditions
          </a>{" "}
          &{" "}
          <a
            className="terms-checkbox-label-a"
            target="_blank"
            href="/privacy-policy"
          >
            Privacy Policy
          </a>
        </label>
      </div>

      <p>
        A {otpLength} digit otp will be sent via SMS to verify your phone number
      </p>
      <button
        className={`otp-btn ${isVerify ? "approve" : "disapprove"} ${
          loading && "disapprove"
        }`}
        onClick={onSignup}
        disabled={!isChecked}
      >
        Send OTP
        {loading && <LoadingSpinner spinner={true} />}
      </button>
      <div id="recaptcha-container"></div>
    </div>
  );
}

export { LoginCard };
