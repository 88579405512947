import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../utils/api/apiPaths";
interface itemsDataInterface {
  items: [];
}
const initialState: itemsDataInterface = {
  items: [],
};

const THUNK_METHODS = {
  GET_ITEMS: "getItems",
};

export const getAllItems = createAsyncThunk(
  THUNK_METHODS.GET_ITEMS,
  async () => {
    const getItemsFeed = await fetch(`${process.env.REACT_APP_API_URL}/${API.ITEMS.GET_ALL_ITEMS}`, {
      method: "POST",
    });
    const itemsJson = await getItemsFeed.json();
    return itemsJson || [];
  }
);

export const AllItemsSlice = createSlice({
  name: "itemsFeed",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllItems.fulfilled, (state, action) => {
      state.items = action.payload.item;
    });
  },
});

export default AllItemsSlice.reducer;
