import React from "react";
import "./spinner.css";

interface LoadingSpinnerProps {
  spinner?: boolean;
}

export default function LoadingSpinner({
  spinner = false,
}: LoadingSpinnerProps) {
  return (
    <div className="spinner-container">
      <div className="spinner">
        {spinner ? (
          <div className="loader">
            <div className="loaderBar"></div>
          </div>
        ) : (
          <img
            className="spinner-img"
            src="/Images/loader.gif"
            alt="Loading..."
          />
        )}
      </div>
    </div>
  );
}
export { LoadingSpinner };
