import React from "react";

const OrderPlacedMessage = () => {
    return (
        <div className="order-success">
          <div className="order-success-icon">
            <img className="order-placed-img" src="/Images/order-placed.gif" alt="order placed" />
          </div>
          <div className="order-success-message">
            <h2>Order Placed Successfully!</h2>
            <p>Thank you for placing your order. We appreciate your business!</p>
          </div>
        </div>
        
    );
};

export { OrderPlacedMessage }    