import { useEffect } from "react";
import { useAppDispatch } from "../../appState/reduxTypedHooks";
import { ProductsListCard } from "../../components/productsListCard/ProductsListCard";
import {
  setDisableFooter,
  setTitle,
} from "../../appState/componentSlices/utilSlice/utilSlice";
import { headers } from "../../constant/titles";
import { batch } from "react-redux";

const Home = () => {
  // get details from verify state and show otp modal
  const dispatch = useAppDispatch();
  useEffect(() => {
    batch(() => {
      dispatch(setTitle(headers.home));
      dispatch(setDisableFooter(false));
    });
  }, []);
  return (
    <div className="home-container">
      <div className="main-content">
        <ProductsListCard />
      </div>
    </div>
  );
};

export { Home };
