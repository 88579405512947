export const getIndianDate = (date: Date) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dateIST = new Date(
    new Date(date).toLocaleString("en-US", {
      timeZone: "Asia/Kolkata",
    })
  );
  const dateNumber = dateIST.getDate();
  return !date
    ? ""
    : `${dateNumber < 10 ? "0" : ""}${dateNumber} ${
        months[dateIST.getMonth()]
      }, ${dateIST.getFullYear().toString().slice(2)}`;
};
