import React from "react";

type Props = {
  children: React.ReactNode;
  Header?: React.FunctionComponent;
  Footer?: React.FunctionComponent;
  style?: React.CSSProperties;
  contentHeight?: number;
  borderRadius?: number;
  backgroundColor?: string;
  boxShadow?: string;
};

export const Card: React.FC<Props> = ({
  children,
  style,
  Header,
  Footer,
  contentHeight,
  borderRadius,
  backgroundColor,
  boxShadow,
}) => {
  return (
    <div
      className="card-container"
      style={{
        borderRadius: `${borderRadius}px`,
        backgroundColor,
        boxShadow,
        ...style,
      }}
    >
      {Header && <Header />}
      <div
        className="card-content"
        style={{
          height: contentHeight,
        }}
      >
        {children}
      </div>
      {Footer && <Footer />}
    </div>
  );
};
