import { Navigate, useLocation } from "react-router";

function RedirectIncorrectPath({ children }: { children: JSX.Element }) {
  const location = useLocation();
  if (location.pathname.includes("undefined")) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

export { RedirectIncorrectPath };
