// create a accordion component that will be used to display the categories

import { Link } from "react-router-dom";
import { useAppSelector } from "../../appState/reduxTypedHooks";
import { Accordion } from "../accordion/Accordion";
import { CategoryCard } from "../categoryCard/CategoryCard";
interface CategoryAccordionProps {
  categories: any;
  type: string;
  length?: number;
}
const CategoryAccordion = (props: CategoryAccordionProps) => {
  const { categories, type, length = 0 } = props;

  return (
    <div className="category-accordion">
      <Accordion
        subHeadingText={"See All"}
        subHeadingLink={type}
        footer={
          <div className="product-tile">
            {categories?.map((category: any) => (
              <div className="product-tile-item" key={category._id}>
                <CategoryCard
                  key={category._id}
                  category={category}
                  type={type}
                />
              </div>
            ))}
          </div>
        }
        height={"30vh"}
        showDetails={false}
        heading={`${type}`}
        length={length}
      ></Accordion>
    </div>
  );
};

export { CategoryAccordion };
