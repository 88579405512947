import { Card } from "../card/Card";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../appState/reduxTypedHooks";
import {
  getAllProducts,
  Product,
} from "../../appState/componentSlices/allProductsFeed";
import { ProductCard } from "../productCard/ProductCard";
import { Accordion } from "../accordion/Accordion";
import { CategoryAccordion } from "../categoryAccordion/CategoryAccordion";
import LoadingSpinner from "../loader/LoadingSpinner";
function ProductsListCard() {
  const loader = useAppSelector((state) => state.ProductsData.loader);
  // const productsByBrand =
  //   useAppSelector((state) => state.ProductsData.allProducts?.slice(0, 10)) ||
  //   [];
  const categories = useAppSelector((state) => state.ProductsData.allProducts);
  const brands = useAppSelector((state) => state.ProductsData.brands);

  return (
    <div>
      {loader ? (
        <div className="spinner-container-main">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <div className="category-accordian">
            <CategoryAccordion
              categories={categories}
              length={categories.length}
              type={"Categories"}
            />
          </div>
          <div className="brand-accordian">
            <CategoryAccordion
              categories={brands}
              type={"brands"}
              length={brands?.length}
            />
          </div>
        </>
      )}
    </div>
  );
}

export { ProductsListCard };
