import { TrackOrder } from "../../components";

const TrackOrderPage = () => {
  return (
    <div className="track-order-container">
      <div className="main-content">{/* <TrackOrder /> */}</div>
    </div>
  );
};

export { TrackOrderPage };
