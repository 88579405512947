export const errorMessages = {
  USER: {
    INVALID_EMAIL: "Invalid email",
    INVALID_PASSWORD: "Invalid password",
    INVALID_PHONE_NUMBER: "Invalid phone number",
  },
  CART: {
    NO_PRODUCT: "No product in cart add some product",
  },
  PRODUCT: {
    NO_PRODUCT: "No product found",
  },
};
