import { Accordion } from "../accordion/Accordion";
import { ItemCart } from "../itemCart/ItemCart";
import { ProductTile } from "../productTile/ProductTile";

interface CartsProductsAccordionProps {
  cart: any;
}
const CartProductsAccordion = (props: CartsProductsAccordionProps) => {
  const { cart } = props;
  return (
    <Accordion
      footer={
        <div className="product-tile">
          {cart.productInCart?.products &&
            cart.productInCart.products.map((item: any) => (
              <ProductTile key={item.id} {...item} />
            ))}
        </div>
      }
      height={"30vh"}
      showDetails={false}
      heading={"Products"}
      subHeading={<div>Total : {cart?.productInCart?.cartTotalSP}</div>}
    >
      <div>
        {cart.productInCart?.products &&
          cart.productInCart.products.map((item: any) => (
            <ItemCart key={item.id} {...item} />
          ))}
      </div>
    </Accordion>
  );
};

export { CartProductsAccordion };
