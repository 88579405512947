// create a hook to fetch config data

import { useEffect, useState } from "react";
interface ConfigType {
  data: [
    {
      timeSlots: {
        slot1: string[];
        slot2: string[];
        slot3: string[];
      };
      paymentOptions: string[];
    }
  ];
}
const data: ConfigType = {
  data: [
    {
      timeSlots: {
        slot1: ["12-02", "05-07", "12-02"],
        slot2: ["05-07", "12-02", "05-07"],
        slot3: ["12-02", "05-07", "12-02"],
      },
      paymentOptions: ["cash"],
    },
  ],
};
const useConfig = () => {
  const [config, setConfig] = useState(data);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/getConfig`);
        const data = await response.json();
        setConfig(data);
        setLoading(false);
      } catch (err: any) {
        setError(err);
        setLoading(false);
      }
    };
    fetchConfig();
  }, []);

  return { config, loading, error };
};

export default useConfig;
