import { configureStore } from "@reduxjs/toolkit";
import { AllItemsSlice } from "./componentSlices/allItemsFeed.slice";
import { AllProductsSlice } from "./componentSlices/allProductsFeed";
import { productInCartSlice } from "./componentSlices/cartSlice/ProductInCartSlice";
import { CheckoutOrderSlice } from "./componentSlices/checkoutSlice/checkoutSlice";
import { OrderSlice } from "./componentSlices/orderSlice/OrdersSlice";
import { userLoginSlice } from "./componentSlices/userSlice/userLoginSlice";
import { utilSlice } from "./componentSlices/utilSlice/utilSlice";

export const store = configureStore({
  reducer: {
    ItemsData: AllItemsSlice.reducer,
    ProductsData: AllProductsSlice.reducer,
    productInCart: productInCartSlice.reducer,
    checkoutOrder: CheckoutOrderSlice.reducer,
    allUserOrders: OrderSlice.reducer,
    orderStatus: OrderSlice.reducer,
    userOtp: userLoginSlice.reducer,
    util: utilSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
