export const toastMessages = {
  TYPE: {
    ERROR: "error",
    SUCCESS: "success",
  },
  MESSAGE: {
    SIGNED_OUT: "Signed out successfully",
    REQUIRED_FIELDS: "Please fill all required fields",
    ORDER_SUCCESS: "Order placed successfully",
    ORDER_FAIL: "Unable to place order",
    CUSTOMER_LOCATION_SUCCESS: "Location Added Successfully",
    CUSTOMER_LOCATION_FAILED:
      "Failed to get Location, Allow chrome notification",
    OTP_SUCCESS: "Otp send successfully",
    OTP_FAIL: "Error while sending OTP, refresh the page and try again",
    LOGIN_SUCCESS: "Login Successfull",
    LOGIN_FAIL: "Login Failed, refresh the page and try again",
  },
};
