import OtpInput from "../otpInput/OtpInput";
import LoadingSpinner from "../loader/LoadingSpinner";
import { useAppSelector } from "../../appState/reduxTypedHooks";
import { Ref } from "react";

type otp = {
  show?: boolean;
  number: string;
  onChange: (value: string) => void;
  otp: string;
  isLoading: boolean;
  onClose?: () => {};
  onSignup: () => {};
  otpRef: Ref<HTMLDivElement> | undefined;
};

const OtpVerification = (props: otp) => {
  const { onChange, otp, isLoading, onSignup, number, otpRef } = props;
  return (
    <div ref={otpRef} className="otp-card-container">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <h2>OTP Verification</h2>
          <p>Enter the otp you recieved to</p>
          <p className="user-number">+91 {number}</p>
          <OtpInput value={otp} onChange={onChange} />
          <button onClick={onSignup}>Resend OTP</button>
        </div>
      )}
    </div>
  );
};

export { OtpVerification };
