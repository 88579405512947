import { createPortal } from "react-dom";

type ChildrenType = {
  children: React.ReactNode;
};

const portalRoot = document.getElementById("portal-container") as HTMLElement;

const ReactPortal: React.FC<ChildrenType> = ({ children }) => {
  return createPortal(children, portalRoot);
};

export default ReactPortal;
