import { Accordion } from "../accordion/Accordion";

interface PreviousAddressAccordionProps {
  previousAddressValue: any;
  previousAddress: Array<any>;
}
function PreviousAddressAccordion(props: PreviousAddressAccordionProps) {
  const { previousAddressValue, previousAddress } = props;
  return (
    <>
      {previousAddress.length > 0 ? (
        <Accordion
          innerHeading={"View Previous Address"}
          count={previousAddress.length}
        >
          <div className="previous-address-input">
            {previousAddress.map((element, index) => {
              return (
                <div className="previous-address-container" key={index}>
                  <p
                    className="previous-address-data"
                    onClick={() => previousAddressValue(element)}
                  >
                    {element?.address} {element?.city} <br />
                    {element?.landmark}
                    {element?.currentLocation && (
                      <a
                        className="location-text"
                        href={element?.currentLocation}
                        target="_blank"
                      >
                        <br />
                        <img
                          className="location-image"
                          src="/Images/location.svg"
                          alt=""
                        />
                        location
                      </a>
                    )}
                  </p>
                </div>
              );
            })}
          </div>
        </Accordion>
      ) : (
        <Accordion innerHeading={"View Previous Address"}>
          <p style={{ padding: "20px" }}>No Previous address available</p>
        </Accordion>
      )}
    </>
  );
}

export { PreviousAddressAccordion };
