import { useEffect, useState } from "react";
import {
  Address,
  UserDetailsInterface,
} from "../../appState/componentSlices/userSlice/@types/User.Initerface";
import {
  getUserDetails,
  updateUserDetails,
} from "../../appState/componentSlices/userSlice/userLoginSlice";
import { useAppDispatch, useAppSelector } from "../../appState/reduxTypedHooks";
import { Accordion } from "../../components";
import "./profile.scss";
import { batch } from "react-redux";
import {
  setDisableFooter,
  setTitle,
} from "../../appState/componentSlices/utilSlice/utilSlice";
import { headers } from "../../constant/titles";
function Profile() {
  const userDetails: UserDetailsInterface = useAppSelector(
    (state) => state.userOtp.userDetails
  );
  const dispatch = useAppDispatch();
  const { phone, shippingAddress, name = "Not Set" } = userDetails;

  useEffect(() => {
    batch(() => {
      dispatch(setTitle(headers.profile));
      dispatch(getUserDetails());
      dispatch(setDisableFooter(false));
    });
  }, []);
  const [isEditing, setIsEditing] = useState(false);
  const [userName, setUserName] = useState(name);

  useEffect(() => {
    setUserName(name);
  }, [userDetails]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleUpdateClick = () => {
    setIsEditing(false);
    // dispatch thunk for updating user details
    dispatch(
      updateUserDetails({
        name: userName,
      })
    );
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
  };

  return (
    <div className="profile-container">
      <div className="profile-title">
        <div className="profile-image">{String(phone)[0]?.toUpperCase()}</div>
        <h3>Welcome {phone}</h3>
      </div>
      <div className="profile-info">
        <div>
          <div>
            <p>Name</p>
            {isEditing ? (
              <div className="profile-name-container">
                <input
                  type="text"
                  className="edit-profile profile-name"
                  value={userName}
                  onChange={handleNameChange}
                />
                <button className="btn edit-btn" onClick={handleUpdateClick}>
                  Update
                </button>
              </div>
            ) : (
              <div className="profile-name-container">
                <p className="profile-name">{userName}</p>
                <button className="btn edit-btn" onClick={handleEditClick}>
                  Edit
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="profile-address">
        <Accordion heading="Shipping Address" showDetails={true}>
          {shippingAddress?.length ? (
            shippingAddress.map((userAddress: Address, index: number) => {
              const {
                city = "Not Set",
                address = "Not Set",
                landmark = "Not Set",
              } = userAddress;
              return (
                <div className="address-container" key={index}>
                  <div className="location-svg">
                    <img
                      src="Images/location.svg"
                      alt="location"
                      width={20}
                      height={20}
                    />
                  </div>
                  <div className="address-info">
                    <p>City : {city}</p>
                    <p>Address: {address}</p>
                    <p>Landmark: {landmark}</p>
                  </div>
                </div>
              );
            })
          ) : (
            <p>You don't have any address </p>
          )}
        </Accordion>
      </div>
      <div className="new-arrivals"></div>
    </div>
  );
}
export { Profile };
