import React from "react";
import "./TermsAndConditions.scss";

export const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions-component">
      <div className="terms-and-conditions-head">
        <div className="back">{/* &lt; */}</div>

        <h2 className="terms-and-conditions-head-h2">Terms & Conditions</h2>

        <div className="back"></div>
      </div>
      <div className="terms-and-conditions-body">
        <p className="terms-and-conditions-body-p">
          <strong>Effective Date:</strong> 01/03/2024
        </p>
        <p className="terms-and-conditions-body-p">
          By using the Priyam Store grocery delivery service, you agree to the
          following terms and conditions. Please read them carefully.
        </p>
        <h3 className="terms-and-conditions-body-h3">1. Use of Service</h3>
        <ul className="terms-and-conditions-body-ul">
          <li className="terms-and-conditions-body-li">
            <strong>Eligibility:</strong> You must be at least 18 years old and
            capable of entering into legally binding agreements to use our
            services.
          </li>
          <li className="terms-and-conditions-body-li">
            <strong>Account:</strong> You are responsible for maintaining the
            confidentiality of your account and password.
          </li>
          <li className="terms-and-conditions-body-li">
            <strong>Service Availability:</strong> Our service is available in
            selected areas. Availability of products and delivery slots may
            vary.
          </li>
        </ul>

        <h3 className="terms-and-conditions-body-h3">2. Orders and Payment</h3>
        <ul className="terms-and-conditions-body-ul">
          <li className="terms-and-conditions-body-li">
            <strong>Order Placement:</strong> Orders can be placed through our
            mobile app or website. All orders are subject to availability and
            confirmation of payment.
          </li>
          <li className="terms-and-conditions-body-li">
            <strong>Pricing:</strong> Prices are listed in the app and may
            change without notice. Taxes and delivery charges may apply.
          </li>
          <li className="terms-and-conditions-body-li">
            <strong>Payment:</strong> We accept payments through various payment
            gateways. By providing your payment details, you authorize us to
            charge the total amount for your order.
          </li>
        </ul>

        <h3 className="terms-and-conditions-body-h3">3. Delivery</h3>
        <ul className="terms-and-conditions-body-ul">
          <li className="terms-and-conditions-body-li">
            <strong>Delivery Times:</strong> We aim to deliver within the
            estimated time frames, but delivery times are not guaranteed.
          </li>
          <li className="terms-and-conditions-body-li">
            <strong>Delivery Address:</strong> It is your responsibility to
            ensure that the delivery address provided is accurate. Failed
            deliveries due to incorrect addresses may incur additional charges.
          </li>
        </ul>

        <h3 className="terms-and-conditions-body-h3">4. Returns and Refunds</h3>
        <ul className="terms-and-conditions-body-ul">
          <li className="terms-and-conditions-body-li">
            <strong>Returns:</strong> We accept returns for damaged or defective
            products within 10 days of delivery. Certain items may not be
            eligible for returns perishable goods.
          </li>
          <li className="terms-and-conditions-body-li">
            <strong>Refunds:</strong> Refunds will be processed through the
            original payment method within 10 days of approval.
          </li>
        </ul>

        <h3 className="terms-and-conditions-body-h3">
          5. Limitation of Liability
        </h3>
        <p className="terms-and-conditions-body-p">
          We are not liable for any indirect, incidental, or consequential
          damages arising from the use of our services, including but not
          limited to product availability, delivery delays, or payment issues.
        </p>

        <h3 className="terms-and-conditions-body-h3">6. Changes to Terms</h3>
        <p className="terms-and-conditions-body-p">
          We reserve the right to modify these terms at any time. Continued use
          of the service following any changes constitutes acceptance of the new
          terms.
        </p>

        <h3 className="terms-and-conditions-body-h3">7. Governing Law</h3>
        <p className="terms-and-conditions-body-p">
          These terms are governed by the laws of Bhopal / Madhya Pradesh.
        </p>
      </div>
    </div>
  );
};
