const SearchBar = () => {
  return (
    <div className="search-input-container">
      <img height="20vh" src="Images/search-line.svg" alt="" />
      <input className="search-input-element" placeholder="Search" />
    </div>
  );
};

export { SearchBar };
