import {
  getProductInCart,
  removeProductFromCart,
  addProductToCart,
} from "../appState/componentSlices/cartSlice/ProductInCartSlice";
import { useAppDispatch, useAppSelector } from "../appState/reduxTypedHooks";
type AddToCart = {
  product: any;
  quantity: number;
};
const useCart = () => {
  const dispatch = useAppDispatch();

  // remove product from cart by calling redux thunk action
  const removeFromCart = async ({ product, quantity }: AddToCart) => {
    await dispatch(removeProductFromCart({ product, quantity }));
  };

  // add product to cart by calling redux thunk action
  const addToCart = async ({ product, quantity }: AddToCart) => {
    await dispatch(addProductToCart({ product, quantity }));
  };

  // get cart by calling api redux thunk action
  const cart = useAppSelector((state) => state.productInCart);

  const cartData = async () => {
    await dispatch(getProductInCart(""));
  };

  return {
    cartData,
    cart,
    addToCart,
    removeFromCart,
  };
};

export default useCart;
