import { useEffect, useRef } from "react";
import useCart from "../../hooks/useCart";
import { useAppSelector } from "../../appState/reduxTypedHooks";

const CartIconContainer = () => {
  const { cart } = useCart();
  const ref = useRef<HTMLImageElement>(null);
  const cartLoader = useAppSelector((state) => state.productInCart.loader);
  useEffect(() => {
    if (cartLoader) {
      // add animation class cart-icon-img-animation
      ref.current?.classList.add("cart-icon-img-animation");
    } else {
      // remove animation class cart-icon-img-animation
      ref.current?.classList.remove("cart-icon-img-animation");
    }
  }, [cartLoader]);
  return (
    <div className="footer-cart-container-circle">
      {cart?.productInCart?.length > 0 && (
        <div className="product-counter-container">
          {cart?.productInCart?.length}
        </div>
      )}
      <img
        ref={ref}
        className="cart-icon-img "
        src="/Images/shopping-cart-line.svg"
        alt=""
      />
    </div>
  );
};

export { CartIconContainer };
