import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../appState/reduxTypedHooks";
import { getAllOrders } from "../../appState/componentSlices/orderSlice/OrdersSlice";
import { ErrorMessage, OrderCard } from "../../components";
import { OrderInterface } from "../../typeDefination";
type selectedOrderType = "Pending" | "Delivered" | "All Orders";
const no_order = "/Images/no_order.svg";
const OrderList = () => {
  const [selectedOrderType, setSelectedOrderType] =
    useState<selectedOrderType>("Pending");
  const dispatch = useAppDispatch();
  const { allUserOrders, pendingOrders, deliveredOrders, loader }: any =
    useAppSelector((state) => state.allUserOrders);
  const ordersStatusObject = {
    Pending: pendingOrders,
    Delivered: deliveredOrders,
    "All Orders": allUserOrders,
  };
  useEffect(() => {
    dispatch(getAllOrders());
  }, []);

  return (
    <div className="orders-container">
      <div className="order-status">
        {ordersStatusObject["All Orders"]?.length > 0 &&
          Object.keys(ordersStatusObject).map(
            (orderType: any, index: number) => (
              <div
                className={
                  `status-category` +
                  (selectedOrderType === orderType ? " active" : "")
                }
                onClick={() => setSelectedOrderType(orderType)}
                key={index}
              >
                <p className="order-status-message"> {orderType}</p>
              </div>
            )
          )}
      </div>

      {loader ? (
        <p>Loading...</p>
      ) : (
        <div className="order-list">
          {ordersStatusObject[selectedOrderType]?.length > 0 ? (
            <ul>
              {ordersStatusObject[selectedOrderType].map(
                (order: OrderInterface, index: number) => (
                  <OrderCard order={order} key={index} />
                )
              )}
            </ul>
          ) : (
            <ErrorMessage icon={no_order} message="No Orders here..." />
          )}
        </div>
      )}
    </div>
  );
};

export { OrderList };
