import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signOut,
} from "firebase/auth";
import { auth } from "../firebase.config";
export const setUpRecaptcha = (number: string) => {
  const captchaOptions = {
    size: "invisible",
    "expired-callback": () => {},
  };
  const recaptchaVerifier = new RecaptchaVerifier(
    "recaptcha-container",
    captchaOptions,
    auth
  );
  recaptchaVerifier.render();
  return signInWithPhoneNumber(auth, number, recaptchaVerifier);
};

export const firebaselogOut = () => {
  return signOut(auth);
};
