import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API } from "../../../utils/api/apiPaths";
interface CheckOutSliceState {
  checkoutData: { [key: string]: any };
  orderStatus: boolean;
  checkoutOrderDetails: {
    userPreviousAddresses: string[];
    inputAddress: string;
    preferredDeliverySlot: string;
    contact: string;
    paymentMethod: string;
    currentLocation: string;
    addressLandmark: string;
  };
  loader: boolean;
}

const initialState: CheckOutSliceState = {
  checkoutData: {},
  orderStatus: false,
  checkoutOrderDetails: {
    userPreviousAddresses: [],
    inputAddress: "",
    preferredDeliverySlot: "",
    contact: "",
    paymentMethod: "cash",
    currentLocation: "",
    addressLandmark: "",
  },
  loader: false,
};
// create async thunk
export const getCheckoutData = createAsyncThunk(
  "checkout/getCheckoutData",
  async (checkoutData: any) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/${API.CHECKOUT.CHECKOUT}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(checkoutData),
    });
    const checkoutDataJson = await response.json();
    return checkoutDataJson || [];
  }
);

// crate checkout slice with redux thunk middleware
export const CheckoutOrderSlice = createSlice({
  name: "checkout",
  initialState: initialState,
  reducers: {
    // set checkout data
    setCheckoutData: (state, action) => {
      state.checkoutOrderDetails = action.payload;
    },
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setOrderStatus: (state, action) => {
      state.orderStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCheckoutData.pending, (state) => {
        state.orderStatus = false;
      })
      .addCase(getCheckoutData.fulfilled, (state, action) => {
        state.orderStatus = action?.payload?.status;
        state.checkoutData = action.payload;
      })
      .addCase(getCheckoutData.rejected, (state, action) => {
        state.orderStatus = false;
        state.checkoutData = [];
      });
  },
});

export const { setCheckoutData, setLoader, setOrderStatus } =
  CheckoutOrderSlice.actions;

export default CheckoutOrderSlice.reducer;
