import { Link } from "react-router-dom";
import useConfig from "../../hooks/useConfig";
import { Accordion } from "../accordion/Accordion";
import { ContactAccordion } from "../contactAccordion/ContactAccordion";
import { PaymentAccordion } from "../paymentAccordion/PaymentAccordion";
import { PreviousAddressAccordion } from "../previousAddressAccordion/PreviousAddressAccordion";
import { TimeSlotAccordion } from "../timeSlotAccordion/TimeSlotAccordion";
import { useAppDispatch, useAppSelector } from "../../appState/reduxTypedHooks";
import { UserInterface } from "../loginInput/LoginInput";

import { UserDetailsInterface } from "../../appState/componentSlices/userSlice/@types/User.Initerface";
import { setToastMessage } from "../../appState/componentSlices/utilSlice/utilSlice";
import { toastMessages } from "../../constant/toastMessage";
import { useRef } from "react";

export type AddressAccordionProps = {
  checkoutData: any;
  setCheckoutData: any;
  config: any;
};

function AddressAccordion({
  checkoutData,
  setCheckoutData,
}: AddressAccordionProps) {
  const { config } = useConfig();
  const dispatch = useAppDispatch();
  const addressRef = useRef<HTMLDivElement | null>(null);
  const previousAddressValue = (e: any) => {
    const { city, address, landmark, currentLocation } = e;
    dispatch(
      setCheckoutData({
        ...checkoutData,
        city: city,
        inputAddress: address,
        addressLandmark: landmark,
        currentLocation,
      })
    );
    addressRef.current &&
      addressRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const userDetails: UserDetailsInterface = useAppSelector(
    (state) => state.userOtp.userDetails
  );
  const previousAddress = userDetails?.shippingAddress || [];
  const googleMapLink = "https://www.google.com/maps/place/";
  const customerLiveLocation = () => {
    try {
      navigator.geolocation.getCurrentPosition((position) => {
        dispatch(
          setCheckoutData({
            ...checkoutData,
            currentLocation:
              googleMapLink +
              position.coords.latitude +
              "+" +
              position.coords.longitude,
          })
        );
      });
      dispatch(
        setToastMessage({
          type: toastMessages.TYPE.SUCCESS,
          message: toastMessages.MESSAGE.CUSTOMER_LOCATION_SUCCESS,
          show: true,
        })
      );
    } catch (error) {
      dispatch(
        setToastMessage({
          type: toastMessages.TYPE.ERROR,
          message: toastMessages.MESSAGE.CUSTOMER_LOCATION_FAILED,
          show: true,
        })
      );
    }
  };
  return (
    <Accordion heading={"Address"} showDetails={true}>
      <div className="address-accordion-container">
        <div className="location-container">
          <button
            className="location-button"
            type="button"
            onClick={customerLiveLocation}
          >
            <img className="location-icon" src="/Images/location.svg" alt="" />{" "}
            Add my current location
          </button>
          {checkoutData.currentLocation && (
            <Link to={checkoutData.currentLocation} target="__blank">
              <p className="location-text">
                See you current location on google maps
              </p>
            </Link>
          )}
        </div>
        <div className="address-form" ref={addressRef}>
          <div className="address-field-container">
            <p className="address-heading">
              Address <sup className="required-field">*</sup>{" "}
            </p>
            <input
              type="text"
              className="address-input"
              name="inputAddress"
              id="inputAddress"
              placeholder="Enter Address"
              onChange={(e) => {
                dispatch(
                  setCheckoutData({
                    ...checkoutData,
                    inputAddress: e.target.value,
                  })
                );
              }}
              value={checkoutData.inputAddress}
            />
          </div>

          <div className="address-field-container">
            {" "}
            <p className="address-heading">
              City <sup className="required-field"></sup>{" "}
            </p>{" "}
            <input
              className="address-input"
              type="text"
              placeholder="Enter City"
              onChange={(e) => {
                dispatch(
                  setCheckoutData({ ...checkoutData, city: e.target.value })
                );
              }}
              value={checkoutData.city}
            />
          </div>

          <div className="address-field-container">
            {" "}
            <p className="address-heading">
              Landmark <sup className="required-field">*</sup>{" "}
            </p>{" "}
            <input
              name="addressLandmark"
              id="addressLandmark"
              className="address-input"
              type="text"
              placeholder="Enter Landmark"
              onChange={(e) => {
                dispatch(
                  setCheckoutData({
                    ...checkoutData,
                    addressLandmark: e.target.value,
                  })
                );
              }}
              value={checkoutData.addressLandmark}
            />{" "}
          </div>
        </div>
      </div>
      {previousAddress.length > 0 && (
        <>
          <p className="or-container">OR</p>
          <PreviousAddressAccordion
            previousAddressValue={previousAddressValue}
            previousAddress={previousAddress}
          />
        </>
      )}
      <ContactAccordion
        checkoutData={checkoutData}
        setCheckoutData={setCheckoutData}
        config={config}
      />
      <TimeSlotAccordion
        config={config}
        checkoutData={checkoutData}
        setCheckoutData={setCheckoutData}
      />
      <PaymentAccordion
        checkoutData={checkoutData}
        setCheckoutData={setCheckoutData}
      />
    </Accordion>
  );
}

export { AddressAccordion };
