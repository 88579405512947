import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API } from "../../../utils/api/apiPaths";

interface ProductsDataInterface {
  productInCart: any | {};
  loader: boolean;
  error?: string;
  status?: string;
}
const initialState: ProductsDataInterface = {
  productInCart: {},
  loader: false,
};
const THUNK_METHODS = {
  GET_PRODUCTS_IN_CART: "getProductsInCart",
};
export const getProductInCart = createAsyncThunk(
  THUNK_METHODS.GET_PRODUCTS_IN_CART,
  async (productId: string) => {
    const getProductInCarts = await fetch(`${process.env.REACT_APP_API_URL}/${API.CART.GET_PRODUCT_IN_CART}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ productId }),
    });
    const productInCartJson = await getProductInCarts.json();
    return productInCartJson || [];
  }
);
export const addProductToCart = createAsyncThunk(
  "cart/addToCart",
  async (addToCartData: any) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/${API.CART.ADD_TO_CART}`, {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(addToCartData),
    });

    const addToCartDataJson = await response.json();

    return addToCartDataJson || [];
  }
);
export const removeProductFromCart = createAsyncThunk(
  "cart/removeFromCart",
  async (removeFromCartData: any) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/${API.CART.REMOVE_FROM_CART}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(removeFromCartData),
    });
    const removeFromCartDataJson = await response.json();
    return removeFromCartDataJson || [];
  }
);
export const productInCartSlice = createSlice({
  name: "productInCart",
  initialState: initialState,
  reducers: {
    // set cart data
    setProductInCart: (state, action) => {
      state.productInCart = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductInCart.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProductInCart.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.productInCart = { ...action.payload.data };
      })
      .addCase(getProductInCart.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });

    builder
      .addCase(addProductToCart.pending, (state) => {
        state.status = "loading";
        state.loader = true;
      })
      .addCase(addProductToCart.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loader = false;
        state.productInCart = { ...action.payload.data };
      })
      .addCase(addProductToCart.rejected, (state, action) => {
        state.status = "failed";
        state.loader = false;
        state.error = action.error.message;
      });

    builder
      .addCase(removeProductFromCart.pending, (state) => {
        state.status = "loading";
        state.loader = true;
      })
      .addCase(removeProductFromCart.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loader = false;
        state.productInCart = { ...action.payload.data };
      })
      .addCase(removeProductFromCart.rejected, (state, action) => {
        state.status = "failed";
        state.loader = false;
        state.error = action.error.message;
      });
  },
});

export default productInCartSlice.reducer;
export const { setProductInCart } = productInCartSlice.actions;
