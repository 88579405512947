import React from "react";

export const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-component">
      <div className="privacy-policy-head">
        <div className="back">{/* &lt; */}</div>
        <h2 className="privacy-policy-head-h2">Privacy Policy</h2>
        <div className="back"></div>
      </div>
      <div className="privacy-policy-body">
        <p>
          <strong>Effective Date:</strong> 01/03/2024
        </p>
        <p>
          At Priyam Store, we are committed to protecting your privacy. This
          Privacy Policy outlines how we collect, use, and safeguard your
          personal information when you use our grocery delivery services,
          including interactions with our mobile application, website, and
          payment gateways.
        </p>

        <h3 className="privacy-policy-body-h3">1. Information We Collect</h3>

        <ul>
          <li>
            <strong>Personal Information:</strong> Name, email address, phone
            number, delivery address, payment information.
          </li>
          <li>
            <strong>Order Information:</strong> Items purchased, delivery
            details, order history.
          </li>
          <li>
            <strong>Technical Information:</strong> IP address, device
            information, browser type, and app usage data.
          </li>
        </ul>

        <h3 className="privacy-policy-body-h3">
          2. How We Use Your Information
        </h3>
        <p>We use your information to:</p>
        <ul>
          <li>Process and deliver orders.</li>
          <li>Facilitate payments and manage payment gateway interactions.</li>
          <li>
            Communicate with you regarding your orders, promotions, and updates.
          </li>
          <li>Improve our services by analyzing user data.</li>
          <li>Comply with legal requirements.</li>
        </ul>

        <h3 className="privacy-policy-body-h3">3. Payment Information</h3>
        <p>
          We use third-party payment gateways to process your payments. These
          gateways comply with industry standards and regulations to secure your
          financial information. [Your Company Name] does not store your
          complete payment information.
        </p>

        <h3 className="privacy-policy-body-h3">4. Sharing Your Information</h3>
        <p>
          We do not share your personal information with third parties, except:
        </p>
        <ul>
          <li>
            With trusted service providers (e.g., delivery services, payment
            processors) who help us operate our business.
          </li>
          <li>When required by law, to comply with legal obligations.</li>
        </ul>

        <h3 className="privacy-policy-body-h3">5. Data Security</h3>
        <p>
          We use industry-standard encryption and security measures to protect
          your information. However, no online service is 100% secure. By using
          our app, you acknowledge and accept these risks.
        </p>

        <h3>6. Your Rights</h3>
        <p>
          You have the right to access, modify, or delete your personal
          information at any time. You can contact us at Priyam Store to request
          such changes.
        </p>
        <h3 className="privacy-policy-body-h3">7. Updates to Privacy Policy</h3>
        <p>
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by updating the date at the top of this policy.
        </p>
      </div>
    </div>
  );
};
