import React, { Ref, useEffect, useState } from "react";
import {
  setOtpScreen,
  setLoading,
  verifyOtp,
  setModal,
  sendOtp,
} from "../../appState/componentSlices/userSlice/userLoginSlice";
import { useAppDispatch, useAppSelector } from "../../appState/reduxTypedHooks";
import { LoginCard } from "../loginCard/LoginCard";
import { OtpVerification } from "../otpVerification/OtpVerification";
import { toastMessages } from "../../constant/toastMessage";
import { setToastMessage } from "../../appState/componentSlices/utilSlice/utilSlice";
import { getProductInCart } from "../../appState/componentSlices/cartSlice/ProductInCartSlice";

type LoginProps = {
  loginRef: Ref<HTMLDivElement> | undefined;
};

const Login = (props: LoginProps) => {
  const { loginRef } = props;
  const { otpScreen, phoneNumber, otpLength } = useAppSelector(
    (state) => state.userOtp
  );

  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const onChange = (value: string) => {
    setOtp(value);
  };
  const otpVerify = async () => {
    const number = `+91${phoneNumber.replace(/\s+/g, "")}`;
    if (otp.trim().length === otpLength && otpScreen) {
      setIsLoading(true);
      try {
        const resultAction = await dispatch(verifyOtp({ number, otp }));

        if (verifyOtp.fulfilled.match(resultAction)) {
          await dispatch(getProductInCart(""));
          dispatch(
            setToastMessage({
              type: toastMessages.TYPE.SUCCESS,
              message: toastMessages.MESSAGE.LOGIN_SUCCESS,
              show: true,
            })
          );

          dispatch(setModal(false));
        } else {
          dispatch(
            setToastMessage({
              type: toastMessages.TYPE.ERROR,
              message: toastMessages.MESSAGE.LOGIN_FAIL,
              show: true,
            })
          );

          dispatch(setModal(true));
        }
        setOtp("");
      } catch (error) {
        dispatch(
          setToastMessage({
            type: toastMessages.TYPE.ERROR,
            message: toastMessages.MESSAGE.LOGIN_FAIL,
            show: true,
          })
        );
        dispatch(setModal(true));
        console.error("Error verifying OTP:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };
  const onSignup = async () => {
    try {
      const formatPhone = `+91${phoneNumber.trim()}`;

      const sendOtpResult = await dispatch(sendOtp(formatPhone));

      if (sendOtpResult?.payload?.status === true) {
        dispatch(setOtpScreen(true));
        dispatch(
          setToastMessage({
            type: toastMessages.TYPE.SUCCESS,
            message: toastMessages.MESSAGE.OTP_SUCCESS,
            show: true,
          })
        );
      } else {
        dispatch(
          setToastMessage({
            type: toastMessages.TYPE.ERROR,
            message: toastMessages.MESSAGE.OTP_FAIL,
            show: true,
          })
        );
      }
    } catch (error) {
      dispatch(
        setToastMessage({
          type: toastMessages.TYPE.ERROR,
          message: toastMessages.MESSAGE.OTP_FAIL,
          show: true,
        })
      );
      console.error("Error during signup:", error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    otpVerify();
  }, [otp]);

  return (
    <>
      {!otpScreen ? (
        <LoginCard loginRef={loginRef} onSignup={onSignup} />
      ) : (
        <OtpVerification
          otpRef={loginRef}
          isLoading={isLoading}
          number={phoneNumber}
          onChange={onChange}
          otp={otp}
          onSignup={onSignup}
        />
      )}
    </>
  );
};

export { Login };
