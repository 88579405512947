import { Link, useNavigate } from "react-router-dom";
import { setModal } from "../../appState/componentSlices/userSlice/userLoginSlice";
import { useAppDispatch, useAppSelector } from "../../appState/reduxTypedHooks";
import { CartIconContainer } from "../cartIconContainer/CartIconContainer";
import { NavBarOptions } from "../navbarOptions/NavbarOptions";
const homeIcon = "/Images/home-com.svg";
const categoriesIcon = "/Images/categories.svg";
const ordersIcon = "/Images/shop-solid.svg";
const profileIcon = "/Images/user-solid.svg";
const Footer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    userOtp: { isLoggedIn },
    util: { disableFooter },
  } = useAppSelector((state) => state);

  const handleClick = (url: string) => {
    if (!isLoggedIn) {
      return dispatch(setModal(true));
    }
    navigate(url);
  };
  return (
    <>
      {!disableFooter ? (
        <div className="footer-container">
          <Link className="iconContainer" to="/">
            <NavBarOptions icon={homeIcon} heading="Home" />
          </Link>
          <div
            className="iconContainer"
            onClick={() => handleClick("/allOrders")}
          >
            <NavBarOptions icon={ordersIcon} heading="Orders" />
          </div>
          <div
            className="cart-icon-container"
            onClick={() => handleClick("/cart")}
          >
            <CartIconContainer />
          </div>
          <div
            className="iconContainer"
            onClick={() => handleClick("/profile")}
          >
            <NavBarOptions icon={profileIcon} heading="Profile" />
          </div>
          <Link className="iconContainer" to="/categories">
            <NavBarOptions icon={categoriesIcon} heading="Categories" />
          </Link>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export { Footer };
