import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../utils/api/apiPaths";
interface ProductsDataInterface {
  allProducts: AllProducts[];
  loader: boolean;
  allProductsByCategory: AllProducts[];
  brands: AllProducts[];
}
type AllProducts = {
  _id: string;
  products: Product[];
};
export type Product = {
  slabPricing: any[];
  itemName: string;
  itemMRPperUnit: string;
  itemSellingPricePerUnit: string;
  images?: Images[];
};

type Images = {
  public_id: string;
  secure_url: string;
};

const initialState: ProductsDataInterface = {
  allProducts: [],
  loader: false,
  allProductsByCategory: [],
  brands: [],
};

const THUNK_METHODS = {
  GET_PRODUCTS: "getProducts",
  GET_PRODUCTS_BY_CATEGORY: "getProductsByCategory",
  GET_BRANDS: "getBrands",
};

export const getAllProducts = createAsyncThunk(
  THUNK_METHODS.GET_PRODUCTS,
  async (productBody: {}) => {
    const getProductsFeed = await fetch(`${process.env.REACT_APP_API_URL}/${API.PRODUCTS.GET_ALL_PRODUCTS}`, {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(productBody),
    });

    const allProducts = await getProductsFeed.json();
    return allProducts || [];
  }
);
export const getAllProductsByCategory = createAsyncThunk(
  THUNK_METHODS.GET_PRODUCTS_BY_CATEGORY,
  async (productBody: {}) => {
    const getProductsFeed = await fetch(`${process.env.REACT_APP_API_URL}/${API.PRODUCTS.GET_ALL_PRODUCTS}`, {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(productBody),
    });

    const allProductsByCategory = await getProductsFeed.json();
    return allProductsByCategory || [];
  }
);
export const getAllBrands = createAsyncThunk(
  THUNK_METHODS.GET_BRANDS,
  async (productBody: {}) => {
    const getProductsFeed = await fetch(`${process.env.REACT_APP_API_URL}/${API.PRODUCTS.GET_ALL_PRODUCTS}`, {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(productBody),
    });

    const allProductsByCategory = await getProductsFeed.json();
    return allProductsByCategory || [];
  }
);

export const AllProductsSlice = createSlice({
  name: "ProductsFeed",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllProducts.pending, (state, action) => {
        state.allProducts = action.payload || [];
        state.loader = true;
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.allProducts = action.payload.data;
        state.loader = false;
      });
    builder
      .addCase(getAllProductsByCategory.pending, (state, action) => {
        state.allProductsByCategory = action.payload || [];
        state.loader = true;
      })
      .addCase(getAllProductsByCategory.fulfilled, (state, action) => {
        state.allProductsByCategory = action.payload.data;
        state.loader = false;
      });
    builder
      .addCase(getAllBrands.pending, (state, action) => {
        state.brands = action.payload || [];
        state.loader = true;
      })
      .addCase(getAllBrands.fulfilled, (state, action) => {
        state.brands = action.payload.data;
        state.loader = false;
      });
  },
});

export default AllProductsSlice.reducer;
